@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-layout-sider {
  background: #fff !important;
}

body {
  font-family: 'Inter';
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.custom-tooltip {
  font-size: 12px;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-item > .ant-badge a:hover {
  color: #000 !important;
  font-weight: 600;
}

ul[nz-menu] li[nz-menu-item]:hover,
ul[nz-menu] li[nz-menu-item] a:hover {
  color: #000;
}

.ant-btn-primary {
  color: #4b4b4b !important;
}
